import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import OutsideClickHandler from 'react-outside-click-handler';

import windowResizeHook from 'hooks/resize/resize.hook';
import { Props } from './DropDownList.interface';

import {
  dropdown,
  dropdown___open,
  dropdown__twoColumns,
  dropdown__threeColumns,
  dropdown___packages,
  dropdown__button,
  dropdown__title,
  dropdown__toggle,
  dropdown__toggle__icon,
  dropdown__toggle___home,
  list,
  list___home,
} from './DropDownList.scss';

export const DropDownList = ({ children, title, columns, isHome, dropDownCaret }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dimensions = windowResizeHook();
  const screenSm = dimensions.width < 1200;
  const isPackageDropdown = title === 'Packages';

  const styles = {
    toggle: isHome ? dropdown__toggle___home : '',
    list: isHome ? list___home : '',
    package: isPackageDropdown ? dropdown___packages : '',
    column: columns > 1 ? (columns > 2 ? dropdown__threeColumns : dropdown__twoColumns) : '',
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menu = isOpen ? { icon: faMinus } : { icon: faPlus };

  const burgerListOpen = {
    display: isOpen && screenSm ? 'flex' : '',
  };

  const defaultStyle = {
    transition: 'opacity 75ms ease-in-out',
    opacity: 0,
    display: 'none',
  };

  const transitionStyles = {
    entering: { display: 'flex' },
    entered: { opacity: 1, display: 'flex' },
    exiting: { opacity: 0.5 },
    exited: { opacity: 0, display: 'none' },
    unmounted: {},
  };

  return (
    <div
      className={`${dropdown} ${isOpen && !isHome ? dropdown___open : ''}`}
      onClick={() => toggleMenu()}
      role="presentation"
    >
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <div className={dropdown__button}>
          <span className={dropdown__title}>
            {title}
            {dropDownCaret && !screenSm && <FontAwesomeIcon icon={faAngleDown} size="1x" />}
          </span>

          <div className={`${dropdown__toggle} ${styles.toggle}`}>
            <span className={dropdown__toggle__icon}>
              <FontAwesomeIcon icon={menu.icon} title="toggle drop-down list" />
            </span>
          </div>
        </div>
        <Transition in={isOpen} timeout={75}>
          {(state) => (
            <ul
              className={`${list} ${styles.package} ${styles.column} ${styles.list}`}
              style={{
                ...burgerListOpen,
                ...defaultStyle,
                /* tslint:disable */
                ...transitionStyles[state],
                /* tslint:enable */
              }}
            >
              {children}
            </ul>
          )}
        </Transition>
      </OutsideClickHandler>
    </div>
  );
};
