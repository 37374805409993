import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';

import { Link } from 'components/common/link/Link';
import { Props } from './ListItemLink.interface';

import { item, item__link, item__icon } from './ListItemLink.scss';

export const ListItemLink = ({ to, children, onClick }: Props): JSX.Element => (
  <li className={item}>
    <Link to={to} className={item__link} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} size="xs" className={item__icon} />
      {children}
    </Link>
  </li>
);
